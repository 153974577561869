import React from 'react'
import Layout from '@components/Layout'
import { Link } from 'gatsby'
import { Seo } from '@components/Seo'
import PageTitle from '@components/PageTitle'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { TitleParagraph } from '@global'

const Eventwrap = styled.div``
const Eventcard = styled.div``

const Eventpage = ({ data }) => (
  <Layout>
    <Seo title="Upcoming Events | The Town and Business Guide For Residents and Visitors" />
    <PageTitle title="Upcoming Events" />
    <TitleParagraph>
      Welcome to our events page, here you will find a list of upcoming events in Sowerby Bridge. If you have a public event in Sowerby Bridge and would like it listed please contact us and we respond back to you as soon as possible.
    </TitleParagraph>
    <Eventwrap>
      {data.publishedevents.edges.map(({ node }) => (
        <Eventcard key={node.id}>
          <Link to={node.slug}>
            <h2>{node.title}</h2>
          </Link>
          <p>{node.displaydate}</p>
        </Eventcard>
      ))}
    </Eventwrap>
    <Eventwrap>
      <h4>Archived Events</h4>
      {data.archivedevents.edges.map(({ node }) => (
        <Eventcard key={node.id}>
          <Link to={node.slug}>
            <h4>{node.title}</h4>
          </Link>
        </Eventcard>
      ))}
    </Eventwrap>
    If you have an event that is applicable to the local community then please get in touch with us and we will be happy to list it on our site.
  </Layout>
)

export const eventgraph = graphql`
  query Eventquery {
    publishedevents: allDatoCmsEvent(filter: { archived: { eq: false } }, sort: { fields: date, order: ASC }) {
      edges {
        node {
          id
          title
          slug
          displaydate
        }
      }
    }
    archivedevents: allDatoCmsEvent(filter: { archived: { eq: true } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`

export default Eventpage
